<template>
  <div>
    <b-row>
      <b-col v-if="!newLead" cols="12" class="d-flex align-items-center">
        <span class="title-card font-medium-1 my-1">Appointment</span>
      </b-col>
      <b-col cols="12">
        <b-row class="text-left">
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  input-id="title"
                  v-model="lead.appTitle"
                  label="label"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  :options="G_EVENT_TITLES_OPTIONS"
                  :reduce="(val) => val.value"
                  :disabled="isDisabled"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              v-slot="{ errors }"
              name="Filter"
              rules="required"
            >
              <b-form-group label="Seller" label-for="appSeller">
                <v-select
                  input-id="appSeller"
                  v-model="lead.appSeller"
                  label="label"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sellers"
                  :disabled="isDisabled"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  :class="errors[0] ? 'border-danger' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              v-slot="{ errors }"
              name="Location"
              rules="required"
            >
              <b-form-group label="Location" label-for="location">
                <b-form-input
                  id="location"
                  v-model="lead.location"
                  :state="errors[0] ? false : null"
                  :disabled="isDisabled"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Description" label-for="appDescription">
              <b-form-textarea
                id="appDescription"
                v-model="lead.appDescription"
                :disabled="isDisabled"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col md="4">
            <b-form-group label="Date" label-for="appDate">
              <flat-pickr
                v-model="lead.appDate"
                id="appDate"
                placeholder="Date"
                class="form-control"
                :config="configFlatPickr"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="From" label-for="appFrom">
              <kendo-timepicker
                :format="'HH:mm'"
                v-model="lead.appFrom"
                :interval="30"
                :class="[
                  'w-100 rounded bg-transparent',
                  { 'text-white': isDarkSkin },
                ]"
                placeholder="Hour"
                style="height: 2.73rem"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="To" label-for="appTo">
              <kendo-timepicker
                :format="'HH:mm'"
                v-model="lead.appTo"
                :interval="30"
                :class="[
                  'w-100 rounded bg-transparent',
                  { 'text-white': isDarkSkin },
                ]"
                placeholder="Hour"
                style="height: 2.73rem"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" class="pt-2">
            <validation-provider>
              <b-form-group>
                <b-form-input
                  :value="
                    lead.state
                      ? lead.state
                      : lead.state_h
                      ? lead.state_h
                      : 'UNK'
                  "
                  disabled
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    modul: {
      type: Number,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
    newLead: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation(() => {});

    return {
      refFormObserver,
      getValidationState,
    };
  },
  data() {
    return {
      isLoading: false,
      minDate: new Date(),
      maxDate: new Date(2050, 9, 1),
      currentDate: new Date(2017, 9, 15, 10, 30),
      minTime: new Date(1950, 0, 1, 0, 0, 0),
      maxTime: new Date(2049, 11, 31, 24, 0, 0),
      valueTime: "00:00:00",
      isDisabled: false,
      sellers: [],
      configFlatPickr: {
        dateFormat: "m/d/Y",
        locale: "en",
        minDate: `${moment().format("MM/DD/YYYY")}`,
      },
    };
  },
  mounted() {},
  async created() {
    //promise all
    await Promise.all([
      this.getOwners(),
      this.setInitialDate(),
      this.setInitialFrom(),
    ]);

    this.removePreloader();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_EVENT_TITLES_OPTIONS: "CrmEventStore/G_EVENT_TITLES",
      G_OWNERS: "CrmGlobalStore/G_OWNERS",
    }),
  },
  methods: {
    ...mapActions({
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      A_SET_EVENT: "CrmEventStore/A_SET_EVENT",
      A_GET_USER_APPOINTMENT_SN: "CrmLeadStore/A_GET_USER_APPOINTMENT_SN",
      A_GET_HOUR_SYSTEM: "global-store/A_GET_HOUR_SYSTEM",
    }),
    setInitialUser() {
      this.lead.appUserId = this.currentUser.appUserId;
      this.lead.created_users = this.currentUser.appUserId;
    },
    setInitialDate() {
      this.lead.appDate = moment().format("MM/DD/YYYY");
    },
    async setInitialFrom() {
      try {
        const response = await this.A_GET_HOUR_SYSTEM(this.lead.state);
        let nowDate = moment().format("MM/DD/YYYY");

        //GetHour close 30 min California
        let nowHour = moment(nowDate + " " + response);
        let fixedHour = 30 - (nowHour.minute() % 30);
        let dateTime = moment(nowHour)
          .add(fixedHour, "minutes")
          .format("HH:mm");
        this.lead.appFrom = dateTime;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    setInitialTo(from) {
      //30 mins more
      let to = moment(from, "HH:mm").add(30, "minutes").format("HH:mm");

      this.lead.appTo = to;
    },
    async getOwners() {
      try {
        let roles = "";
        let validate_filter = null;
        switch (this.modul) {
          case 4:
            roles = "[1,13]";
            break;
          case 2:
            roles = "[1,2,5]";
            validate_filter = 1;

            break;
          default:
            roles = "[1,2,3,5,13]";
        }

        await this.A_GET_OWNERS({
          modul: this.modul,
          body: { roles, type: "1", validate_filter: validate_filter },
        });
        this.sellers = this.G_OWNERS;
        this.setInitialUser();
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    datesMsn() {
      //Dates to  send Msn
      this.lead.dateSp = new Date(
        this.lead.appDate.replace(/-/g, "/")
      ).toLocaleDateString("es-ES", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
      this.lead.dateEn = new Date(
        this.lead.appDate.replace(/-/g, "/")
      ).toLocaleDateString("en-EN", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
      //Hour Msn
      let dateNew = moment(this.lead.appDate + " " + this.lead.appFrom);
      this.lead.hourMsn = dateNew.format("h:mm A");
    },
  },
  watch: {
    "lead.appFrom"(newVal, oldVal) {
      if (newVal) {
        this.setInitialTo(newVal);
      }
    },
    "lead.appSeller"(newVal, oldVal) {
      if (newVal) {
        this.lead.idCatcher =  newVal;
      }
    },
    "lead": {
      async handler(newVal) {
        if (newVal) {
         this.$emit("getAppointment", newVal);
        }
      },
      deep: true,
    },
  },
  directives: {
    Ripple,
  },
};
</script>
