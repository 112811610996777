<template>
  <div>
    <b-button
      v-show="isModuleActualEqualTwo"
      variant="success"
      class="mr-1 mb-1"
      @click="openModalSearch()"
    >
      <feather-icon icon="PlusIcon" size="15" class="mr-50 text-white" />search
      payment
    </b-button>
    <b-button
      :variant="color_btn"
      :class="btnClass"
      :size="btnSize"
      :disabled="isResponsible"
      @click="onOpenSidebar"
    >
      <feather-icon icon="PlusIcon" size="15" :class="btnIconClass" />
      {{ btnText }}
    </b-button>
    <b-sidebar
      id="add-new-lead-social-network-sidebar"
      :visible="!loading"
      bg-variant="white"
      sidebar-class="sidebar-xl"
      shadow
      backdrop
      right
      header-class="text-primary"
      lazy
      @hidden="onCloseSidebar"
    >
      <template #header>
        <div class="sidebar-header">
          <h2>Create Lead</h2>
          <button class="btn-close" @click="onCloseSidebar">
            <feather-icon icon="XIcon" size="24" />
          </button>
        </div>
      </template>

      <template #default>
        <!-- BODY -->
        <div id="container-create-lead-sn" class="px-4 container-create">
          <validation-observer ref="refFormLeadObserver">
            <CatchmentCreateSn
              :lead="lead"
              @setOrganic="setProgramOrganicFlyer"
              @setPhone="setProgramPhoneFlyer"
              @getSources="getSources"
            />
            <BasicInformationCreateLeadSn
              :lead="lead"
              :is-valid-nickname="isValidNickname"
            />

            <template v-if="lead.addEvidence">
              <PersonalInformationCreateLeadSn
                :lead="lead"
                :is-valid-mobile="isValidMobile"
                :is-valid-email="isValidEmail"
              />
            </template>

            <template v-if="lead.moreInfo">
              <MoreInformation :lead="lead" />
            </template>

            <template v-if="lead.addEvidence">
              <FilterCreateLeadSn :lead="lead" />
            </template>
          </validation-observer>
        </div>
      </template>

      <template #footer>
        <div
          class="d-flex align-items-center justify-content-between px-3 py-2 w-100"
        >
          <div class="">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="mr-2"
              @click="resetForm"
            >
              <div
                class="d-flex align-items-center justify-content-center px-2"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="16"
                  class="text-white"
                />
                <span class="btn-create-lead">Reset</span>
              </div>
            </b-button>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mr-2"
              @click="onCloseSidebar"
            >
              <div
                class="d-flex align-items-center justify-content-center px-2"
              >
                <feather-icon icon="TrashIcon" size="16" class="text-white" />
                <span class="btn-create-lead">Cancel</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class=""
              @click="onSubmit"
            >
              <div
                class="d-flex align-items-center justify-content-center px-2"
              >
                <feather-icon icon="CheckIcon" size="16" class="text-white" />
                <span class="btn-create-lead">Create</span>
              </div>
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>

    <b-toast id="toast-validation-create-lead">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <amg-icon icon="AmgIcon" size="26" />
          <strong class="mr-auto pl-1 text-primary">Warning validation</strong>
        </div>
      </template>

      <div>
        <p v-for="(value, index) in toastData" :key="index" class="m-0">
          <span class="font-weight-bolder">{{ index + 1 }}.</span>
          {{ value.label }} {{ value.error }}
        </p>
      </div>
    </b-toast>
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import CatchmentCreateSn from "@/views/social-network/views/leads/components/lead-create/CatchmentCreateSn.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import BasicInformationCreateLeadSn from "@/views/social-network/views/leads/components/lead-create/BasicInformationCreateLeadSn.vue";
import PersonalInformationCreateLeadSn from "@/views/social-network/views/leads/components/lead-create/PersonalInformationCreateLeadSn.vue";
import MoreInformation from "@/views/social-network/views/leads/components/lead-create/MoreInformation.vue";
import BillingInformation from "@/views/social-network/views/leads/components/lead-create/BillingInformation.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { amgApi } from "@/service/axios";
import FilterCreateLeadSn from "@/views/social-network/views/leads/components/lead-create/FilterCreateLeadSn.vue";

export default {
  name: "LeadCreateSocial",
  components: {
    BillingInformation,
    MoreInformation,
    PersonalInformationCreateLeadSn,
    BasicInformationCreateLeadSn,
    CatchmentCreateSn,
    ValidationProvider,
    ValidationObserver,
    FilterCreateLeadSn,
    ModalSearchPayment,
  },
  directives: {
    Ripple,
  },
  props: {
    color_btn: {
      type: String,
      default: "success",
      required: false,
    },
    btnSize: {
      type: String,
      default: "",
      required: false,
    },
    btnClass: {
      type: String,
      default: "",
      required: false,
    },
    btnText: {
      type: String,
      default: "Create",
      required: false,
    },
    btnIconClass: {
      type: String,
      default: "mr-50 text-white",
      required: false,
    },
    metaChatData: {
      type: Object,
      default: () => ({
        isFromChat: false,
        nickname: "",
        programId: null,
        sourceId: null,
        sub_source: null,
        referral: {
          ad_id: null,
          ad_name: null,
        },
      }),
      required: false,
    },
  },
  data() {
    return {
      openModalSearchPayment: false,
      moduleActual: null,
      permitStatus: null,
      lead: {
        // multiply
        multiply: 1,
        // Catchment
        state_h: "CA",
        user_id: null,
        program: [],
        source_id: 7,
        sub_source: 3,
        google_ads: null,
        fanpage_id: null,
        flyer: null,
        sourcesname_id: 17,
        is_organic: false,
        is_organic_temp: false,
        array_contacts: [],

        // Basic information
        nickname: "",
        language: "ES",
        addEvidence: false,
        motive_no_answer_id: null,

        // Personal information
        first_name: "",
        last_name: "",
        mobile: null,
        zipcode: "",
        email: "",
        note: "",
        original_text: "",
        moreInfo: false,
        potential: true,
        reason_not_pontential: null,
        addEvidenceMobile: true,
        motive_no_mobile_id: null,
        name: "",

        // More information
        dob: "",
        state_lead: null,
        type_document: null,
        document: "",
        phone: "",
        street: "",
        city: "",
        // TODO se envia el valor correcto pero no se muestra el correcto
        state: null,
        // TODO agregar numero de itin, ssn dependiendo del tipo del documento
        itin: "",
        ssn: "",

        // Billing information
        cardholdername: "",
        card_expi_month: "",
        card_expi_year: "",
        cardsecuritycode: "",
        cardnumber: "",
        countrycard: "United States",
        zipcodecard: "",
        citycard: "",
        statecard: null,
        streetcard: "",

        // Task
        title: "",
        seller: null,
        subject: "CALL",
        hour: "",
        from: "",
        to: "",
        date: "",
        sms_status: false,
        due_date: "",
        assign: null,
        attend: null,

        recovery: 0,
        last_action: 1,
        program_id: null,
        super: null,
        usercreator: null,
        catcher: null,
        created_by: null,
        created_date: null,

        content: "",
        created_hour: "",
        description: "",
        files: [],
        id: "",
        idMethod: null,
        images: [],
        other: "",

        filterLead: false,
        // appointment
        appTitle: "",
        appSeller: null,
        location: "",
        appDescription: "",
        appDate: "",
        appFrom: "",
        appTo: "",
        created_users: null,
        appType: "event",
        dateSp: null,
        dateEn: null,
        hourMsn: null,
        motive_no_filter_id: null,
        isFromChat: false,
        ad_id: null,
      },
      loading: true,
      optionsStatesAd: [
        {
          label: "Select a State",
          value: null,
        },
      ],
      optionsOwners: [
        {
          label: "Select Owner",
          value: null,
        },
      ],
      optionPrograms: [],
      toastData: [],
      isValidNickname: true,
      isValidEmail: true,
      isValidPhone: true,
      isValidMobile: true,
      businessFlyerOrganicId: "",
      boostCreditFlyerOrganicId: "",
      creditExpertsFlyerOrganicId: "",
      debtSolutionFlyerOrganicId: "",
      taxResearchFlyerOrganicId: "",

      businessFlyerPhoneId: "",
      boostCreditFlyerPhoneId: "",
      creditExpertsFlyerPhoneId: "",
      debtSolutionFlyerPhoneId: "",
      taxResearchFlyerPhoneId: "",
    };
  },
  async created() {
    await this.moduleIdActual();
  },
  async mounted() {
    this.lead.super = this.currentUser.user_id;
    this.lead.usercreator = this.currentUser.user_id;
    this.lead.catcher = this.currentUser.user_id;
    this.lead.created_by = this.currentUser.user_id;
    this.lead.user_id = this.currentUser.user_id;
    this.lead.created_date = this.$options.filters.formatDate(new Date());
    this.lead.seller = this.currentUser.user_id;
    this.getAccessModules();
  },

  computed: {
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_LANGUAGES: "CrmGlobalStore/G_LANGUAGES",
    }),
    ...mapState("SocialNetworkLeadsStore", [
      "S_STATES_LEADS",
      "S_SUB_SOURCES",
      "S_FAN_PAGE_PROGRAMS",
      "S_FLYERS",
      "S_ORGANIC_FLYERS",
      "S_PHONE_FLYERS",
    ]),
    saveWhenNoHavingEnoughInformation() {
      return (
        this.lead.addEvidence === true &&
        this.lead.addEvidenceMobile === true &&
        (!this.lead.first_name || !this.lead.last_name || !this.lead.mobile) &&
        this.lead.motive_no_filter_id == null
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsible() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleIdActual() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    getSources(obj) {
      this.lead.new_source_id = obj[0].source_id;
      this.lead.new_contact_method_id = obj[0].contact_method_id;
      // this.lead.array_contacts = obj[0].array_contacts
    },
    ...mapActions("SocialNetworkLeadsStore", [
      "A_GET_SUB_SOURCES",
      "A_GET_STATE_LEAD",
      "A_GET_OWNERS",
      "A_GET_PROGRAMS",
      "A_GET_SUB_SOURCE_SN",
      "A_GET_FAN_PAGE_PROGRAMS",
      "A_GET_FLYERS",
      "A_FORMAT_DATE",
      "A_CREATE_LEAD_SN",
      "A_GET_NEW_LEADS",
      "A_GET_FLYER_ORGANIC_BY_PROGRAM",
    ]),

    async onSubmit() {
      try {
        console.log("onSubmit", this.lead);
        const validate = await this.$refs.refFormLeadObserver;
        // Validar formulario
        if ((await validate.validate()) && this.isValidEmail) {
          if (this.lead.array_contacts.length == 0) {
            this.showWarningSwal("Add a contact");
            return;
          }

          const resp = await this.showConfirmSwal(
            "Are you sure?",
            "You won't be able to revert this!",
            "question"
          );
          if (resp.isConfirmed) {
            this.addPreloader();
            // Transformar fechas
            this.lead.dob = this.transformDate(this.lead.dob);
            this.lead.date = this.transformDate(this.lead.date);
            if (this.lead.mobile || this.motive_no_answer_id) {
              this.lead.motive_no_mobile_id = null;
              this.lead.motive_no_answer_id = null;
            }

            this.lead.is_organic_temp = this.lead.is_organic;
            if (this.lead.is_organic === null) {
              this.lead.is_organic = 3;
            } else if (this.lead.is_organic === true) {
              this.lead.is_organic = 1;
              this.lead.flyer = this.getFlyerOrganicProgram(
                this.lead.fanpage_id
              );
            } else if (this.lead.is_organic === false) {
              this.lead.is_organic = 2;
            }

            if (
              this.lead.sourcesname_id === 17 &&
              this.lead.sub_source === 3 &&
              this.lead.flyer === null
            ) {
              this.lead.flyer = this.getFlyerOrganicProgram(
                this.lead.fanpage_id
              );
              this.lead.is_organic = 1;
            }

            if (this.lead.sub_source === 10) {
              this.lead.flyer = this.getFlyerPhoneProgram(this.lead.fanpage_id);
            }
            if (this.saveWhenNoHavingEnoughInformation) {
              this.lead.motive_no_filter_id = 3;
            }
            // Enviar peticion a la api
            this.lead.seller = this.lead.seller
              ? this.lead.seller
              : this.currentUser.user_id;
            this.lead.is_organic = this.lead.is_organic_temp;
            this.lead.mobile
              ? (this.lead.potential = true)
              : (this.lead.potential = false);
            this.lead.belongs_module = 15;
            const leadCreated = await this.A_CREATE_LEAD_SN(this.lead);
            if (leadCreated.status === 200) {
              this.lead.multiply = 1;
              this.lead.addEvidence = false;

              const leadId = leadCreated.data[0].lead_id;
              window.open(
                `/socialnetwork/leads/new/dashboard/${leadId}`,
                "_blank"
              );
              this.$emit("leadId", leadId);
              setTimeout(async () => {
                await this.resetForm();
                await this.onCloseSidebar();
                await this.removePreloader();

                this.showSuccessSwal("Success!", "Successful Process", "");
                await this.A_GET_NEW_LEADS({
                  cr: null,
                  date_from: null,
                  date_to: null,
                  lead_status: null,
                  name_text: "",
                  order: "desc",
                  orderby: 10,
                  page: 1,
                  perpage: 10,
                  program: null,
                  state_h: null,
                  type: 1,
                  user_owner: null,
                });
              }, 1000);
            }
          }
        } else {
          const fields = Object.values(validate.fields).map(
            (field) => field.name
          );
          let errors = Object.values(validate.errors);
          errors = errors
            .map((error, index) => ({
              index: fields[index].split("-")[3]
                ? parseInt(fields[index].split("-")[3].split(",")[0])
                : null,
              error: error[0],
              id: fields[index].split(",")[0],
              label: fields[index].split(",")[1],
            }))
            .filter(
              (error) => error.error !== undefined && error.index !== null
            );

          const nickValid = errors.find(
            (err) => err.id == "input-create-lead-10"
          );
          if (!nickValid && !this.isValidNickname) {
            const isNickValidItem = {
              error: "is not unique",
              id: "input-create-lead-10",
              index: 10,
              label: "NickName",
            };
            errors.push(isNickValidItem);
            errors.sort((a, b) => {
              if (a.index > b.index) {
                return 1;
              }
              return -1;
            });
          }
          const phoneValid = errors.find(
            (err) => err.id == "input-create-lead-14"
          );
          if (!phoneValid && this.lead.addEvidence && !this.isValidMobile) {
            const isPhoneValidItem = {
              error: "is not unique",
              id: "input-create-lead-14",
              index: 14,
              label: "Phone(M)",
            };
            errors.push(isPhoneValidItem);
            errors.sort((a, b) => {
              if (a.index > b.index) {
                return 1;
              }
              return -1;
            });
          }

          if (this.lead.addEvidence && !this.isValidEmail) {
            const isEmailValidItem = {
              error: "is not unique",
              id: "input-create-lead-17",
              index: 17,
              label: "Email",
            };
            errors.push(isEmailValidItem);
            errors.sort((a, b) => {
              if (a.index > b.index) {
                return 1;
              }
              return -1;
            });
          }

          this.toastData = errors.sort((a, b) => {
            if (a.index > b.index) {
              return 1;
            }
            return -1;
          });
          this.$bvToast.show("toast-validation-create-lead");
          const input = document.getElementById(`${this.toastData[0].id}`);
          input.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        setTimeout(async () => {
          await this.removePreloader();
        }, 1000);
      }
    },
    async statesAD() {
      const statesLead = (await this.A_GET_STATE_LEAD()).map((state) => ({
        label: state.state,
        value: state.slug,
      }));
      this.optionsStatesAd = [...this.optionsStatesAd, ...statesLead];
    },
    setProgramOrganicFlyer() {
      if (this.S_ORGANIC_FLYERS.length > 4) {
        this.businessFlyerOrganicId = this.S_ORGANIC_FLYERS[0].flyer_id;
        this.boostCreditFlyerOrganicId = this.S_ORGANIC_FLYERS[1].flyer_id;
        this.creditExpertsFlyerOrganicId = this.S_ORGANIC_FLYERS[2].flyer_id;
        this.debtSolutionFlyerOrganicId = this.S_ORGANIC_FLYERS[3].flyer_id;
        this.taxResearchFlyerOrganicId = this.S_ORGANIC_FLYERS[4].flyer_id;
      }
    },
    setProgramPhoneFlyer() {
      if (this.S_PHONE_FLYERS.length > 4) {
        this.businessFlyerPhoneId = this.S_PHONE_FLYERS[0].flyer_id;
        this.boostCreditFlyerPhoneId = this.S_PHONE_FLYERS[1].flyer_id;
        this.creditExpertsFlyerPhoneId = this.S_PHONE_FLYERS[2].flyer_id;
        this.debtSolutionFlyerPhoneId = this.S_PHONE_FLYERS[3].flyer_id;
        this.taxResearchFlyerPhoneId = this.S_PHONE_FLYERS[4].flyer_id;
      }
    },
    getFlyerOrganicProgram(program) {
      switch (program) {
        case 1:
          return this.businessFlyerOrganicId;
        case 2:
          return this.boostCreditFlyerOrganicId;
        case 3:
          return this.creditExpertsFlyerOrganicId;
        case 4:
          return this.debtSolutionFlyerOrganicId;
        case 5:
          return this.taxResearchFlyerOrganicId;
        default:
          return null;
      }
    },
    getFlyerPhoneProgram(program) {
      switch (program) {
        case 1:
          return this.businessFlyerPhoneId;
        case 2:
          return this.boostCreditFlyerPhoneId;
        case 3:
          return this.creditExpertsFlyerPhoneId;
        case 4:
          return this.debtSolutionFlyerPhoneId;
        case 5:
          return this.taxResearchFlyerPhoneId;
        default:
          return null;
      }
    },
    async ownersLeads() {
      const owners = (
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles: "[]", type: "1" },
        })
      ).map((owner) => ({
        label: owner.user_name,
        value: owner.id,
      }));
      this.optionsOwners = [...this.optionsOwners, ...owners];
    },
    async programsAll() {
      const data = await this.A_GET_PROGRAMS();
      this.optionPrograms = [...data];
    },
    async sub_sources() {
      await this.A_GET_SUB_SOURCE_SN();
    },
    onCloseSidebar() {
      // this.$emit('closeSidebar')

      this.resetForm();
      this.loading = true;
      this.lead.addEvidence = false;
      this.lead.is_organic = false;
      this.lead.motive_no_answer_id = null;
      this.lead.motive_no_mobile_id = null;
      this.lead.mobile = null;
      this.lead.motive_no_filter_id = null;
      this.lead.multiply = 1;
    },
    async onOpenSidebar() {
      this.addPreloader();
      await Promise.all([
        await this.statesAD(),
        await this.ownersLeads(),
        await this.programsAll(),
        await this.sub_sources(),
        await this.A_GET_FAN_PAGE_PROGRAMS(),
        await this.initMetaChatData(),
      ]);
      this.removePreloader();
      this.loading = false;
    },
    resetForm() {
      this.lead.state_h = "CA";
      this.lead.user_id = this.currentUser.user_id;
      this.lead.program = [];
      this.lead.source_id = 7;
      this.lead.sub_source = 3;
      this.lead.google_ads = null;
      this.lead.fanpage_id = null;
      this.lead.flyer = null;
      this.lead.sourcesname_id = 17;
      // Motives
      // Basic information
      this.lead.nickname = "";
      this.lead.language = "ES";
      this.lead.addEvidence = false;

      // Personal information
      this.lead.first_name = null;
      this.lead.last_name = null;
      this.lead.mobile = null;
      this.lead.zipcode = "";
      this.lead.email = "";
      this.lead.note = "";
      this.lead.original_text = "";
      this.lead.moreInfo = false;
      this.lead.potential = true;
      this.lead.reason_not_pontential = null;
      this.lead.name = null;

      // More information
      this.lead.dob = "";
      this.lead.state_lead = null;
      this.lead.type_document = null;
      this.lead.document = "";
      this.lead.phone = "";
      this.lead.street = "";
      this.lead.city = "";
      // TODO se envia el valor correcto pero no se muestra el correcto
      this.lead.state = null;
      // TODO agregar numero de itin, ssn dependiendo del tipo del documento
      this.lead.itin = "";
      this.lead.ssn = "";

      // Billing information
      this.lead.cardholdername = "";
      this.lead.card_expi_month = "";
      this.lead.card_expi_year = "";
      this.lead.cardsecuritycode = "";
      this.lead.cardnumber = "";
      this.lead.countrycard = "United States";
      this.lead.zipcodecard = "";
      this.lead.citycard = "";
      this.lead.statecard = null;
      this.lead.streetcar = "";

      // Task
      this.lead.title = "";
      this.lead.seller = null;
      this.lead.subject = "CALL";
      this.lead.hour = "";
      this.lead.from = "";
      this.lead.to = "";
      this.lead.date = "";
      this.lead.sms_status = 0;
      this.lead.due_date = "";
      this.lead.assign = null;
      this.lead.attend = null;

      this.lead.recovery = 0;
      this.lead.last_action = 1;
      this.lead.program_id = null;
      this.lead.super = this.currentUser.user_id;
      this.lead.usercreator = this.currentUser.user_id;
      this.lead.catcher = this.currentUser.user_id;
      this.lead.created_by = this.currentUser.user_id;
      this.lead.created_date = this.$options.filters.formatDate(new Date());

      this.lead.content = "";
      this.lead.created_hour = "";
      this.lead.description = "";
      this.lead.files = [];
      this.lead.id = "";
      this.lead.idMethod = null;
      this.lead.images = [];
      this.lead.other = "";

      this.lead.filterLead = false;
      // appointment
      this.lead.appTitle = "";
      this.lead.appSeller = null;
      this.lead.location = "";
      this.lead.appDescription = "";
      this.lead.appDate = "";
      this.lead.appFrom = "";
      this.lead.appTo = "";
      this.lead.created_users = null;
      this.lead.appType = "event";
      this.lead.dateSp = null;
      this.lead.dateEn = null;
      this.lead.hourMsn = null;
      this.$refs.refFormLeadObserver.reset();

      this.lead.isFromChat = false;
      this.lead.ad_id = null;
    },
    transformDate(date) {
      const data_dob = date.split("/");
      const data =
        data_dob[0] == "" || data_dob[2].length != 4
          ? this.lead.dob
          : `${data_dob[2]}/${data_dob[0]}/${data_dob[1]}`;
      return data.replaceAll("/", "-");
    },
    async initMetaChatData() {
      if (!this.metaChatData.isFromChat) return;
      this.lead.nickname = this.metaChatData.nickname;
      this.lead.isFromChat = this.metaChatData.isFromChat;
      this.lead.fanpage_id = this.metaChatData.programId;
      this.lead.source_id = this.metaChatData.sourceId;
      this.lead.sub_source = this.metaChatData.sub_source;
      this.lead.ad_id = this.metaChatData.referral.ad_id;
    },
  },
  watch: {
    "lead.nickname": async function () {
      const resp = await amgApi.post(
        "/lead/social-network/validate-exists-nickname",
        {
          nickname: this.lead.nickname,
          lead_id: null,
        }
      );
      if (resp.data.code) {
        this.isValidNickname = false;
        this.showInfoSwal(
          `The Nickname already exists: ${resp.data.message}`,
          "Information nick is not unique"
        );
      } else {
        this.isValidNickname = true;
        this.toastData = this.toastData.filter(
          (err) => err.id !== "input-create-lead-10"
        );
      }
    },
    "lead.email": async function () {
      const resp = await amgApi.post(
        "/lead/social-network/validate-exists-email",
        {
          email: this.lead.email,
          lead_id: null,
        }
      );
      if (resp.data.code) {
        this.isValidEmail = false;
        this.showInfoSwal(
          `The email already exists: ${resp.data.message}`,
          "Email is not unique"
        );
      } else {
        this.isValidEmail = true;
        this.toastData = this.toastData.filter(
          (err) => err.id !== "input-create-lead-10"
        );
      }
    },
    "lead.mobile": async function () {
      const resp = await amgApi.post("/lead/social-network/unique-mobile-sn", {
        mobile: this.lead.mobile,
      });
      if (resp.data.code) {
        this.isValidMobile = false;
        this.showInfoSwal(
          `The phone(M) already exists: ${resp.data.message}`,
          "Information phone(M) is not unique"
        );
      } else {
        this.isValidMobile = true;
        this.toastData = this.toastData.filter(
          (err) => err.id !== "input-create-lead-14"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.btn-create-lead {
  padding-top: 0.15rem !important;
  margin-left: 0.3rem;
  font-size: 16px;
}

.b-sidebar-body {
  //background: red !important;
  scroll-behavior: smooth !important;
}

.container-create {
  // overflow-y: scroll !important;
  scroll-behavior: smooth !important;
}

.sidebar-xl {
  width: 110rem !important;
}

.sidebar-header {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;

  h2 {
    color: #0090e7 !important;
    font-weight: 500 !important;
    margin: 0 !important;
  }

  .btn-close {
    background: none;
    border: none;
    color: #1b2337 !important;
  }
}

.dark-layout .sidebar-header .btn-close {
  color: #f1f1f1 !important;
}
</style>
