<template>
  <div>
    <b-row>
      <b-col md="12" class="text-left">
        <b-form-group
          id="fieldset-horizontal"
          label-class="font-bureau-style font-weight-normal color-gray-input-sn"
          label-cols-sm="4"
          label-for="input-horizontal"
          label-cols-lg="2"
          content-cols-sm
          content-cols-lg="10"
          label="Programs"
        >
          <ValidationProvider
            id="input-create-lead-9"
            v-slot="{ errors }"
            rules="required"
            name="input-create-lead-9,Programs"
          >
            <b-row
              class="d-flex justify-content-around align-items-center px-0 content-program"
            >
              <div
                v-for="(program, index) in S_FAN_PAGE_PROGRAMS"
                :key="index"
                :title="program.value"
                style="margin-bottom: 4px"
              >
                <b-button
                  :variant="
                    program.id == data.program_id
                      ? 'primary'
                      : 'outline-primary'
                  "
                  :disabled="metaChatData.isFromChat"
                  @click="selectProgram(program.id)"
                >
                  {{ program.value }}
                </b-button>
              </div>
              <div class="w-100">
                <p
                  v-if="errors[0]"
                  class="text-error-sn text-center m-0 text-danger"
                >
                  Program {{ errors[0] }}
                </p>
              </div>
              <input v-model="lead.fanpage_id" type="radio" class="d-none" />
            </b-row>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <b-form-group :class="errors[0]" label="Source">
            <v-select
              v-model="data.source_id"
              :options="listSource"
              :reduce="(item) => item.id_source"
              label="name"
              :disabled="!lead.fanpage_id || metaChatData.isFromChat"
              @input="onChange(1)"
              maxHeigth="30px"
              style="heigth: 30px"
            >
              <template #selected-option="{ icon_url, name }">
                <b-img
                  rounded
                  fluid
                  style="width: 25px"
                  :src="icon_url ? icon_url : ''"
                  class="mr-1"
                />
                {{ name }}
              </template>
              <template #option="{ icon_url, name }">
                <b-img
                  rounded
                  fluid
                  style="width: 25px"
                  :src="
                    icon_url
                      ? icon_url
                      : 'https://www.cubcadet.com/dw/image/v2/BCSH_PRD/on/demandware.static/Sites-cubcadet-Site/-/default/dw8fca9aff/images/noimagelarge.png'
                  "
                  class="mr-1"
                />
                {{ name }}
              </template>
            </v-select>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <b-form-group :class="errors[0]" label="Contact method">
            <v-select
              v-model="data.contact_method_id"
              :options="listContactMethods"
              :reduce="(item) => item.id_method_contac"
              :disabled="!data.source_id"
              label="name"
              @input="onChange(2)"
            >
              <template #selected-option="{ is_from_flyer, name }">
                <feather-icon
                  size="25"
                  class="mr-1"
                  :class="is_from_flyer == 1 ? 'text-success' : 'text-danger'"
                  :icon="is_from_flyer == 1 ? 'ImageIcon' : 'XSquareIcon'"
                />
                {{ name }}
              </template>

              <template #option="{ is_from_flyer, name }">
                <feather-icon
                  size="25"
                  class="mr-1"
                  :class="is_from_flyer == 1 ? 'text-success' : 'text-danger'"
                  :icon="is_from_flyer == 1 ? 'ImageIcon' : 'XSquareIcon'"
                />
                {{ name }}
              </template>
            </v-select>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SettingsService from "@/views/social-network/views/settings/settings.service";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueToggles from "vue-toggles";

export default {
  name: "CatchmentCreateSn",
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueToggles,
  },
  props: {
    lead: {
      type: Object,
      default: () => ({}),
    },
    metaChatData: {
      type: Object,
      default: () => ({
        isFromChat: false,
        programId: null,
        sourceId: null,
        sub_source: null,
      }),
      required: false,
    },
  },
  data() {
    return {
      data: {
        source_id: "",
        source_name: "",
        contact_method_id: "",
        contact_name: "",
        program_id: "",
        from_flyer_contact_method: "",
      },
      listSource: [],
      listContactMethods: [],
      optionsStatesAd: [
        {
          label: "Select a State",
          value: null,
        },
      ],
      optionsOwners: [
        {
          label: "Select Owner",
          value: null,
        },
      ],
      optionPrograms: [],
      modalChooseFlyer: false,
      sources: [],
      openModal: false,
    };
  },
  async created() {
    await Promise.all([
      await this.getAllSources(),
      await this.statesAD(),
      await this.ownersLeads(),
      await this.programsAll(),
      await this.sub_sources(),
      await this.A_GET_FAN_PAGE_PROGRAMS(),
      await this.getOrganicFlyers(),
    ]);
  },
  async mounted() {
    this.lead.fanpage_id = null;
    await this.initMetaChatData();
  },
  computed: {
    ...mapState("SocialNetworkLeadsStore", ["S_FAN_PAGE_PROGRAMS"]),
  },
  methods: {
    ...mapActions("SocialNetworkLeadsStore", [
      "A_GET_STATE_LEAD",
      "A_GET_OWNERS",
      "A_GET_PROGRAMS",
      "A_GET_SUB_SOURCE_SN",
      "A_GET_FAN_PAGE_PROGRAMS",
      "A_GET_FLYER_ORGANIC_BY_PROGRAM",
      "A_GET_FLYER_PHONE_BY_PROGRAM",
    ]),
    onChange(id) {
      let value = this.listContactMethods.filter(
        (item) => item.id_method_contac == this.data.contact_method_id
      );
      if (id === 1) {
        this.data.contact_method_id = "";
      } else {
        this.data.from_flyer_contact_method = "";
      }
      if (value) {
        if (value[0]) {
          if (value[0].is_from_flyer) {
            this.data.from_flyer_contact_method = value[0].is_from_flyer;
          }
        }
      }
      this.data.source_name = this.listSource.filter(
        (element) => element.id_source == this.data.source_id
      );
      this.data.contact_name = this.listContactMethods.filter(
        (item) => item.id_method_contac == this.data.contact_method_id
      );
      this.getAllContactMethods(this.data);
      this.$emit("getdata", this.data);
    },
    async getAllContactMethods(params) {
      try {
        if (params.source_id) {
          let request = {
            sourceId: params.source_id,
          };
          const { data } = await SettingsService.getAllContactMethods(request);
          this.listContactMethods = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllSources() {
      try {
        const { data } = await SettingsService.getAllSources();
        this.listSource = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getOrganicFlyers() {
      const data = await this.A_GET_FLYER_ORGANIC_BY_PROGRAM();
      if (data.status === 200) {
        this.$emit("setOrganic");
      }
      const data2 = await this.A_GET_FLYER_PHONE_BY_PROGRAM();
      if (data2.status === 200) {
        this.$emit("setPhone");
      }
    },
    // Get Options Selects
    async statesAD() {
      const statesLead = (await this.A_GET_STATE_LEAD()).map((state) => ({
        label: state.state,
        value: state.slug,
      }));
      this.optionsStatesAd = [...this.optionsStatesAd, ...statesLead];
    },
    async ownersLeads() {
      const owners = (
        await this.A_GET_OWNERS({ modul: 15, body: { roles: "[]", type: "1" } })
      ).map((owner) => ({
        label: owner.user_name,
        value: owner.id,
      }));
      this.optionsOwners = [...this.optionsOwners, ...owners];
    },
    async programsAll() {
      const data = await this.A_GET_PROGRAMS();
      this.optionPrograms = [];
      this.optionPrograms = [...data];
      this.optionPrograms = this.optionPrograms.filter(
        (program) => program.id != 9
      );
    },
    async sub_sources() {
      await this.A_GET_SUB_SOURCE_SN();
    },

    selectProgram(id) {
      this.lead.fanpage_id = id;
      this.getAllSources(id);
      this.data.program_id = id;
      this.onChange();
    },
    async initMetaChatData() {
      if (!this.metaChatData.isFromChat) return;
      this.selectProgram(this.metaChatData.programId);
      this.data.source_id = this.metaChatData.sourceId;
      this.onChange(1);
      if (this.metaChatData.sub_source) {
        this.data.contact_method_id = this.metaChatData.sub_source;
        setTimeout(() => {
          this.onChange(2);
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
button.btn.disabled {
  cursor: not-allowed !important;
}
</style>
