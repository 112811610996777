<template>
  <transition name="fade">
    <div class="is-loading-more">
      <span class="mr-1"> {{ text }}</span>
      <div class="dot-container">
        <div class="dot" v-for="n in 3" :key="n"></div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "LoadingMore",
  props: {
    text: {
      type: String,
      required: false,
      default: "Loading more",
    },
  },
};
</script>
<style lang="scss">
.is-loading-more {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding: 0.2rem;
  transform: translateX(-50%);
  background-color: #f8f8f8;

  .dot-container {
    display: flex;
    justify-content: center;
    padding: 1rem;

    .dot {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      background-color: var(--primary-color);
      border-radius: 50%;
      animation: dot-blink 1.5s infinite;
    }

    .dot:nth-child(1) {
      animation-delay: 0s;
    }
    .dot:nth-child(2) {
      animation-delay: 0.3s;
    }
    .dot:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes dot-blink {
    0%,
    80%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
}
.dark-layout {
  .is-loading-more {
    background-color: #22272b;
  }
}
</style>