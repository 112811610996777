<template>
  <div>
    <b-card no-body class="p-2 mt-4">
      <b-row>
        <b-col md="5" class="d-flex align-items-center">
          <h4 class="title-card m-0 my-2">Appointment</h4>
          <p v-if="!lead.first_name || !lead.last_name || !lead.mobile" class="m-0 ml-1 text-danger">
            (First Name, Last Name and Phone (M) are required)
          </p>
        </b-col>
      </b-row>
      <template v-if="lead.first_name && lead.last_name && lead.mobile">
        <appointment-add :lead="lead" :modul="2" :newLead="newLead" />
      </template>
    </b-card>
  </div>
</template>
<script>

import AppointmentAdd from "@/views/social-network/views/leads/components/lead-create/appointment-create-lead/AppointmentAdd.vue";
export default {
  components: {
    AppointmentAdd,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newLead: true
    };
  },
  computed: {},
  methods: {},
  watch:{
    'lead.first_name'(){
      if(this.lead.first_name && this.lead.last_name && this.lead.mobile){
        this.lead.filterLead = true;
      }else{
        this.lead.filterLead = false
      }
    },
    'lead.last_name'(){
      if(this.lead.first_name && this.lead.last_name && this.lead.mobile){
        this.lead.filterLead = true;
      }else{
        this.lead.filterLead = false
      }
    },
    'lead.mobile'(){
      if(this.lead.first_name && this.lead.last_name && this.lead.mobile){
        this.lead.filterLead = true;
      }else{
        this.lead.filterLead = false
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container-task-later {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.task-later-seller {
  grid-template-columns: 2fr 1.5fr 3fr;
}

.task-now-seller {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.point-seller {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #f1f1f1;
  margin-right: 5px;
}

.bg-green {
  background: green !important;
}

.truncate {
  width: 67px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-options {
  width: 85px;
}

.btn-find-seller-later {
  margin-top: 9px;
}
</style>