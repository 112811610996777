<template>
  <div>
    <b-modal
      ref="modal-meta-ads"
      size="lg"
      scrollable
      hide-footer
      @hidden="$emit('hidden')"
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          {{ programName() }}
        </span>
      </template>
      <template #default>
        <filter-slot
          :filter="filterSlot.filters"
          :total-rows="filterSlot.totalRows"
          :paginate="filterSlot.paginate"
          :start-page="filterSlot.startPage"
          :to-page="filterSlot.toPage"
          :filter-principal="filterSlot.filterPrincipal"
          :top-paginate="false"
          :left-col-value="12"
          :right-col-value="0"
          :show-filter-selector-pages="false"
          :input-width="60"
          :show-items-paginate="false"
          :square-pagination="true"
          @onChangeCurrentPage="myProvider()"
          @reload="myProvider()"
        >
          <template #buttons-filter>
            <b-button
              variant="primary"
              class="d-flex justify-content-center align-items-center mr-1"
              size="sm"
              @click="syncWithMeta()"
            >
              <meta-icon-svg :size="24" />
              <span style="padding-left: 3px">Sync Today</span>
            </b-button>
          </template>

          <template #table>
            <div class="px-1">
              <template v-if="isBusy">
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template v-else>
                <div class="container-flyers">
                  <div v-for="(item, index) in metaAds" :key="index">
                    <div
                      class="container-flyers-item"
                      :class="
                        selectedFlyerIds.includes(item.ad_id)
                          ? 'item-selected'
                          : 'cursor-pointer'
                      "
                      v-b-tooltip.hover.top.html="formattedTooltipContent(item)"
                      @click="
                        !selectedFlyerIds.includes(item.ad_id) &&
                          selectFlyer(item)
                      "
                    >
                      <b-avatar
                        :src="item.thumbnail_url"
                        size="8rem"
                        icon="card-image"
                      />
                      <div class="ad-name">
                        {{ item.name | limitChars(14) }}
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="metaAds.length === 0">
                  <div class="text-center text-muted my-2">
                    <strong>No matching records found</strong>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </filter-slot>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MetaService from "@/views/creative/views/meta/service/meta.service.js";
import MetaIconSvg from "@/views/creative/views/meta/components/MetaIconSvg.vue";
import moment from "moment";
export default {
  name: "MetaAdsModal",
  components: {
    MetaIconSvg,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
    selectedFlyer: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      isBusy: false,
      metaAds: [],
      filterSlot: {
        filters: [],
        paginate: {
          currentPage: 1,
          perPage: 12,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search...",
          model: "",
        },
      },
    };
  },
  computed: {
    selectedFlyerIds() {
      return this.selectedFlyer.map((item) => item.id);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.bindSocketFacebookAds();
  },
  mounted() {
    this.toggleModal("modal-meta-ads");
  },
  methods: {
    async myProvider() {
      try {
        this.isBusy = true;
        const params = {
          search_text: this.filterSlot.filterPrincipal.model,
          program_id: this.programId,
          npage: this.filterSlot.paginate.currentPage,
          perpage: 16,
        };
        const { data } = await MetaService.getMetaAds(params);
        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.metaAds = data.data ?? [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isBusy = false;
      }
    },
    programName() {
      const programs = {
        1: "BUSINESS",
        2: "BOOST CREDIT",
        3: "CREDIT EXPERTS",
        4: "DEBT SOLUTION",
        5: "TAX RESEARCH",
        6: "COURT INFO",
        7: "SPECIALIST",
        8: "KEYBOOK",
        9: "PARAGON",
      };
      return programs[this.programId] || "META ADS";
    },
    selectFlyer(item) {
      const flyer = {
        id: item.ad_id,
        urlImage: item.thumbnail_url,
        filename: item.name,
        hasImageFlyerInSoft: item.thumbnail_url_in_soft,
        metaUserId: item.meta_user_id,
      };
      this.$emit("selectFlyer", flyer);
    },
    async syncWithMeta() {
      try {
        this.addPreloader();
        const today = moment().format("YYYY-MM-DD");
        const params = {
          date: today,
          user_id: this.currentUser.user_id,
        };
        const { data } = await MetaService.syncWithMeta(params);
        this.showSuccessSwal(data.message);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    refresh() {
      this.myProvider();
    },
    async bindSocketFacebookAds() {
      window.socket.bind("facebook-ads-notification", async ({ content }) => {
        if (this.currentUser.user_id !== content.user_id) return;
        this.showTimedToast({
          variant: content.variant,
          icon: content.icon,
          timeout: content.timeout,
          title: content.title,
          text: content.text,
        });

        if (content.variant === "success" || content.variant === "error") {
          await this.refresh();
        }
      });
    },
    formattedTooltipContent(item) {
      return `<div>${item.name}<br> Created: ${moment(item.created_at).format(
        "MM/DD/YYYY"
      )}</div>`;
    },
  },
  beforeDestroy() {
    try {
      window.socket.unbind("facebook-ads-notification");
    } catch (error) {
      console.log("facebook-ads-notification", error);
    }
  },
};
</script>
<style lang="scss">
.container-flyers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  .container-flyers-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    object-fit: cover;

    .b-avatar {
      border: 4px solid transparent;
      transition: 0.3s all ease-in-out;
    }
    .ad-name {
      margin-top: 5px;
    }
  }
  .item-selected {
    .b-avatar {
      border: 4px solid #fc424a;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
</style>