<template>
  <div>
    <b-modal
      ref="modal-ad-info"
      @hidden="$emit('hidden')"
      size="payment"
      header-class="custom-modal-header"
      content-class="custom-modal-content"
      modal-class="modal-ad-info"
      no-enforce-focus
      centered
      no-close-on-backdrop
      hide-footer
    >
      <template #modal-header>
        <div></div>
      </template>
      <template #default>
        <LoadingMore class="loadind-ad" :text="text" />
      </template>
    </b-modal>
  </div>
</template>
<script>
import LoadingMore from "@/views/commons/Messenger/components/LoadingMore.vue";
export default {
  components: {
    LoadingMore,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: "Searching for Ad",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.toggleModal("modal-ad-info");
  },
  methods: {},
};
</script>
<style lang="scss">
.modal-ad-info {
  .custom-modal-header {
    background: #f8f8f8 !important;
    color: #000;
  }
  .custom-modal-content {
    border-radius: 20px !important;
    background: #f8f8f8 !important;
    overflow: hidden;

    .loadind-ad {
      font-size: 1.2rem;
      font-weight: 900;
    }
  }
}

.dark-layout {
  .modal-ad-info {
    .custom-modal-header {
      background: #22272b !important;
      color: #fff;
    }
    .custom-modal-content {
      border-radius: 20px !important;
      background: #22272b !important;
    }
  }
}
</style>