<template>
  <div>
    <b-card no-body class="p-2 mt-4">
      <b-row>
        <b-col md="6">
          <h4 class="title-card text-left">Basic Information</h4>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col mb="6" class="text-left">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="input-create-lead-11,Nickname"
          >
            <b-form-group
              id="fieldset-horizontal"
              label-class="font-bureau-style font-weight-normal color-gray-input-sn"
              label-cols-sm="4"
              label-for="input-horizontal"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Nickname"
            >
              <b-form-input
                id="input-create-lead-11"
                v-model="lead.nickname"
                placeholder="Please type nickname"
                class="select-icon-none font-bureau-style border-hover bg-white-c"
                :class="errors[0] || !isValidNickname ? 'border-error-sn' : ''"
                :state="errors[0] || !isValidNickname ? false : null"
                debounce="600"
              />
              <div class="d-flex align-items-center justify-content-center">
                <p v-if="errors[0]" class="text-error-sn text-center">
                  {{ `Nickname ${errors[0]}` }}
                </p>
                <p v-if="errors[0] && !isValidNickname" class="mr-1 ml-1">-</p>
                <p v-if="!isValidNickname" class="text-error-sn text-center">
                  Nickname is not unique
                </p>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col mb="6" class="text-left">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="input-create-lead-12,Language"
          >
            <b-form-group
              id="fieldset-horizontal"
              label="Language"
              label-for="st-ad"
              :state="errors[0] ? false : null"
              label-class="font-bureau-style font-weight-normal color-gray-input-sn"
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              class="m-0"
            >
              <v-select
                id="input-create-lead-12"
                v-model="lead.language"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="languagesOptions"
                :clearable="false"
                :reduce="(el) => el.value"
              />
              <div v-if="errors[0]" class="text-error-sn text-center">
                Language {{ errors[0] }}
              </div>
            </b-form-group>
            <input
              v-model="lead.language"
              type="radio"
              class="d-none bg-green"
            />
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col mb="6" class="text-left">
          <b-form-group
            id="fieldset-horizontal"
            label-class="font-bureau-style font-weight-normal color-gray-input-sn"
            label-cols-sm="4"
            label-for="input-horizontal"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
            label="Did you start dialogue?"
          >
            <VueToggles
              class="vue-toggle"
              height="31"
              width="90"
              checked-text="YES"
              unchecked-text="NO"
              checked-bg="#FF6A6A"
              :dot-color="lead.addEvidence ? 'white' : '#FF6A6A'"
              unchecked-bg="lightgrey"
              :value="lead.addEvidence"
              font-weight="bold"
              @click="onClickToggleDialogue"
            />
          </b-form-group>
        </b-col>
        <b-col mb="6" class="text-left">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="lead.addEvidence === false ? 'required' : ''"
            name="input-create-lead-20,Motive for no answer"
          >
            <b-form-group
              v-if="lead.addEvidence === false"
              label="Motive"
              label-for="st-ad"
              :state="errors[0] ? false : null"
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              class="m-0"
            >
              <div class="d-flex justify-content-start">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="description"
                  v-model="lead.motive_no_answer_id"
                  :options="motivesNoAnswerOptions"
                  :clearable="false"
                  :reduce="(el) => el.id"
                  :style="
                    isCeo || isSupervisor || isTeamLeader
                      ? 'width: 92%'
                      : 'width: 100%'
                  "
                />

                <feather-icon
                  v-if="isCeo || isSupervisor || isTeamLeader"
                  class="text-primary ml-1"
                  icon="PlusCircleIcon"
                  size="18"
                  @click="createEditMotiveModalOn = true"
                />
              </div>
              <div
                v-if="errors[0]"
                class="text-error-sn text-center"
                style="padding-top: 5px"
              >
                Motive {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </b-card>
    <create-edit-motive-modal
      v-if="createEditMotiveModalOn"
      :is-from-reply="true"
      :type-from-reply="typeNoAnswer"
      @close="closeEditMotiveModalOn"
      @reload="getMotiveLossByType"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueToggles from "vue-toggles";
import SettingsService from "@/views/social-network/views/settings/settings.service";
import CreateEditMotiveModal from "@/views/social-network/views/settings/modals/CreateEditMotiveModal.vue";
import vSelect from "vue-select";

export default {
  components: {
    VueToggles,
    vSelect,
    CreateEditMotiveModal,
  },
  props: {
    lead: {
      type: Object,
      default: () => ({}),
    },
    errorNickname: {
      type: Boolean,
      default: false,
    },
    isValidNickname: {
      type: Boolean,
    },
  },
  data() {
    return {
      createEditMotiveModalOn: false,
      languagesOptions: [
        {
          label: "Select a Language",
          value: null,
        },
        {
          label: "Spanish",
          value: "ES",
        },
        {
          label: "English",
          value: "EN",
        },
      ],
      optionsMotive: [
        {
          description: "Select Motive",
          id: null,
        },
      ],
      motivesNoAnswerOptions: [],
      motiveToSend: {},
      typeNoAnswer: 1,
    };
  },
  computed: {
    nickname() {
      return this.lead.nickname;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.getMotiveLossByType();
  },
  async mounted() {
    this.lead.nickname = this.lead.isFromChat ? this.lead.nickname : null;
  },
  methods: {
    ...mapActions("SocialNetworkLeadsStore", ["A_VALIDATE_NICKNAME"]),
    async getMotiveLossByType() {
      this.lead.motive_no_mobile_id = null;
      const data = await SettingsService.getMotiveLossByType({
        type_id: this.typeNoAnswer,
      });
      if (data.status === 200) {
        this.motivesNoAnswerOptions = [...this.optionsMotive, ...data.data];
      }
    },
    closeEditMotiveModalOn() {
      this.createEditMotiveModalOn = false;
    },
    openEditMotiveModalOn() {
      this.createEditMotiveModalOn = true;
    },
    onClickToggleDialogue() {
      this.lead.addEvidence = !this.lead.addEvidence;
      this.lead.motive_no_answer_id = null;
    },
  },
};
</script>

<style lang="scss">
.vue-toggle {
  margin-top: 0 !important;
}
.card {
  box-shadow: 4px 4px 24px 4px rgb(34, 41, 47, 0.1) !important;
}
.dark-layout .card {
  box-shadow: 0 1px 3px 0 rgb(255 255 255 / 0.1),
    0 1px 2px -1px rgb(255 255 255 / 0.1) !important;
  background: #222222 !important;
}
.bigger {
  margin-top: 5px;
  width: 2em;
  height: 2em;
  cursor: pointer;
}
</style>
