<template>
  <div>
    <b-card no-body class="p-2 mt-4">
      <b-row>
        <b-col md="6">
          <h4 class="title-card text-left">Catchment</h4>
        </b-col>
        <b-col md="6 text-right">
          <h4 class="title-card">Catcher: {{ this.currentUser.fullName }}</h4>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col md="4" class="text-left">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="input-create-lead-1,ST/AD"
          >
            <b-col md="12">
              <b-form-group
                id="fieldset-horizontal"
                label="ST/AD"
                label-for="st-ad"
                :state="errors[0] ? false : null"
                label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
              >
                <v-select
                  id="input-create-lead-1"
                  v-model="lead.state_h"
                  selected=""
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="optionsStatesAd"
                  :clearable="false"
                  :reduce="(el) => el.value"
                />
                <p v-if="errors[0]" class="text-error-sn text-center m-0">
                  ST/AD {{ errors[0] }}
                </p>
              </b-form-group>
              <input
                v-model="lead.state_h"
                type="radio"
                class="d-none bg-green"
              />
            </b-col>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="input-create-lead-2,Lead Owner"
          >
            <b-col md="12">
              <b-form-group
                id="fieldset-horizontal"
                label="Lead Owner"
                label-for="lead-owner"
                :state="errors[0] ? false : null"
                label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
              >
                <v-select
                  id="input-create-lead-2"
                  v-model="lead.user_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :clearable="false"
                  :options="optionsOwners"
                  :reduce="(el) => el.value"
                />
                <p v-if="errors[0]" class="text-error-sn text-center m-0">
                  Lead owner {{ errors[0] }}
                </p>
              </b-form-group>
              <input
                v-model="lead.user_id"
                type="radio"
                class="d-none bg-green"
              />
            </b-col>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="input-create-lead-3,Suggestions"
          >
            <b-col md="12">
              <b-form-group
                id="fieldset-horizontal"
                label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                label-cols-sm="4"
                label-for="input-horizontal"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Suggestions"
                :state="errors[0] ? false : null"
              >
                <v-select
                  id="input-create-lead-3"
                  v-model="lead.program"
                  :options="optionPrograms"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :preserve-search="true"
                  placeholder="Pick some"
                  class="rounded"
                  label="name"
                  track-by="name"
                  :preselect-first="true"
                />
                <p
                  v-if="errors[0]"
                  class="text-error-sn text-center m-0 text-danger"
                >
                  Suggestions {{ errors[0] }}
                </p>
              </b-form-group>
            </b-col>
          </ValidationProvider>
        </b-col>

        <b-col md="8" class="text-left">
          <div class="">
            <b-form-group
              id="fieldset-horizontal"
              label-class="font-bureau-style font-weight-normal color-gray-input-sn"
              label-cols-sm="4"
              label-for="input-horizontal"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label=""
            >
              <b-row>
                <b-col md="12" class="">
                  <SourceCard
                    :meta-chat-data="{
                      isFromChat: lead.isFromChat,
                      programId: lead.fanpage_id,
                      sourceId: lead.source_id,
                      sub_source: lead.sub_source,
                    }"
                    @getdata="getData"
                  ></SourceCard>
                </b-col>

                <!-- GOOGLE -->

                <b-col md="6 pt-0" class=""> </b-col>
              </b-row>
            </b-form-group>
          </div>

          <!-- Flyers -->
        </b-col>
        <b-col
          cols="12"
          v-if="
            dataComponentHijo.contact_method_id &&
            dataComponentHijo.from_flyer_contact_method != 1 &&
            dataComponentHijo.program_id
          "
        >
          <b-button variant="primary" size="sm" @click="addNewContactOrganic"
            >Add</b-button
          >
        </b-col>
        <b-col
          cols="5"
          class="mr-2"
          v-if="lead.array_contacts.length == 0"
        ></b-col>
        <b-col cols="5">
          <b-form-group
            v-if="
              dataComponentHijo.contact_method_id &&
              dataComponentHijo.from_flyer_contact_method == 1 &&
              dataComponentHijo.program_id
            "
            id="input-create-lead-10"
            label="Flyer"
            label-cols="4"
            content-cols="8"
            label-class="font-bureau-style font-weight-normal color-gray-input-sn d-flex align-items-center pl-2"
          >
            <div class="content-flyer">
              <div id="input-create-lead-11" class="d-flex margin-flyer pl-1">
                <b-avatar
                  square
                  class="image-flyer"
                  :src="imageFlyer"
                  size="10rem"
                  icon="card-image"
                />
                <div class="d-flex align-items-center">
                  <div>
                    <b-button
                      :variant="!flyer_filename ? 'primary' : 'outline-primary'"
                      size="sm"
                      block
                      @click="openModalSelectFlyer"
                    >
                      Select
                    </b-button>
                    <b-button
                      id="tooltip-button-not-interactive"
                      :variant="flyer_filename ? 'primary' : 'outline-primary'"
                      @click="addNewContact"
                      class="mt-1"
                      size="sm"
                      block
                      >Add</b-button
                    >
                    <b-tooltip
                      v-if="!flyer_filename"
                      target="tooltip-button-not-interactive"
                      noninteractive
                      >Select a flyer!</b-tooltip
                    >
                  </div>
                </div>

                <input v-model="lead.flyer" type="radio" class="d-none" />
              </div>
            </div>
          </b-form-group>
          <b-form-group
            v-if="
              dataComponentHijo.contact_method_id &&
              dataComponentHijo.from_flyer_contact_method == 1 &&
              dataComponentHijo.program_id
            "
            label-cols="4"
            content-cols="4"
            label="Replies"
            label-class="font-bureau-style font-weight-normal color-gray-input-sn d-flex align-items-center pl-2"
          >
            <b-form-spinbutton v-model="lead.multiply" min="1" size="sm" />
          </b-form-group>
        </b-col>

        <b-col cols="7" v-if="lead.array_contacts.length > 0">
          <b-table
            thead-class="text-center"
            striped
            hover
            :fields="fields"
            :items="lead.array_contacts"
            small
            class="mt-1"
            responsive
          >
            <template #cell(has_number)="row">
              <div class="d-flex justify-content-center">
                <b-form-checkbox v-model="row.item.has_number" disabled>
                </b-form-checkbox>
              </div>
            </template>
            <template #cell(source_name)="row">
              <div class="d-flex justify-content-center">
                {{ row.item.source_name }}
              </div>
            </template>
            <template #cell(contact_name)="row">
              <div class="d-flex justify-content-center">
                {{ row.item.contact_name }}
              </div>
            </template>
            <template #cell(flyer)="row">
              <div
                class="d-flex justify-content-center align-items-center"
                v-b-tooltip.hover.top.left
                :title="row.item.flyer"
                @click="getAdPreview(row.item)"
              >
                <b-avatar
                  :src="row.item.url"
                  size="50"
                  square
                  icon="file-earmark-image-fill"
                  class="rounded mr-1"
                  variant="light"
                  style="border-radius: 0.5rem !important"
                />
                {{ row.item.flyer | limitChars(15) }}
              </div>
            </template>
            <template #cell(actions)="row">
              <feather-icon
                icon="Trash2Icon"
                class="text-danger cursor-pointer"
                @click="deleteMethod(row.index)"
              ></feather-icon>
            </template>
          </b-table>
          <div></div>
        </b-col>
      </b-row>

      <!-- Modal Flyer -->
    </b-card>

    <meta-ads-modal
      v-if="openModal"
      :program-id="lead.fanpage_id"
      :selected-flyer="lead.array_contacts"
      @selectFlyer="selectFlyer($event)"
      @hidden="openModal = false"
    />
    <MetaAdInfo v-if="showMetaAdInfo" @close="showMetaAdInfo = false" />
    <preview-ad
      v-if="showPreviewAd"
      :type="'ad_preview'"
      :ad-id="adId"
      :ad-name="adName"
      :meta-user-id="metaUserId"
      @hidden="showPreviewAd = false"
    />
  </div>
</template>

<script>
import SourceCard from "@/views/social-network/views/leads/components/lead-create/SourceCardandMethodContact.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import VueToggles from "vue-toggles";
import MetaAdsModal from "@/views/creative/views/meta/components/MetaAdsModal.vue";
import MetaAdsService from "@/views/creative/views/meta/service/meta.service.js";
import ImagePreview from "@/commons/ImagePreview.vue";
import MetaAdInfo from "@/views/creative/views/meta/components/MetaAdInfo.vue";
import PreviewAd from "@/views/creative/views/meta/components/PreviewAd.vue";

export default {
  name: "CatchmentCreateSn",
  components: {
    vSelect,
    SourceCard,
    VueToggles,
    MetaAdsModal,
    ImagePreview,
    MetaAdInfo,
    PreviewAd,
  },
  props: {
    lead: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataComponentHijo: {
        source_id: "",
        source_name: "",
        contact_method_id: "",
        contact_name: "",
        program_id: "",
        from_flyer_contact_method: "",
      },
      optionsStatesAd: [
        {
          label: "Select a State",
          value: null,
        },
      ],
      optionsOwners: [
        {
          label: "Select Owner",
          value: null,
        },
      ],
      optionPrograms: [],
      optionsFlyers: [],
      sources: [],
      openModal: false,

      imageFlyer: null,
      titleFanPageProgram: null,
      flyer_filename: null,
      fields: [
        {
          key: "has_number",
          label: "Get Number",
        },

        {
          key: "source_name",
          label: "Source",
        },
        {
          key: "contact_name",
          label: "Contact Method",
        },
        {
          key: "flyer",
          label: "Flyer",
          tdClass: "text-center",
        },
        {
          key: "replies",
          label: "Replies",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "text-center",
        },
      ],
      hasImageFlyerInSoft: null,
      showMetaAdInfo: false,
      showPreviewAd: false,
      adId: null,
      adName: null,
      metaUserId: null,
    };
  },
  async created() {
    await Promise.all([
      await this.statesAD(),
      await this.ownersLeads(),
      await this.programsAll(),
      await this.sub_sources(),
      await this.A_GET_FAN_PAGE_PROGRAMS(),
      await this.getOrganicFlyers(),
    ]);
  },
  async mounted() {
    this.lead.fanpage_id = this.lead.isFromChat ? this.lead.fanpage_id : null;
    this.lead.program = [];
    this.lead.array_contacts = [];
    await this.getMetaAdById();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_LANGUAGES: "CrmGlobalStore/G_LANGUAGES",
    }),
    ...mapState("SocialNetworkLeadsStore", [
      "S_STATES_LEADS",
      "S_SUB_SOURCES",
      "S_FAN_PAGE_PROGRAMS",
      "S_FLYERS",
      "S_ORGANIC_FLYERS",
      "S_ORGANIC_FLYERS",
    ]),

    // isChangeState() {
    //   return this.lead.state_h;
    // },
    // isChangeFanPage() {
    //   return this.lead.fanpage_id;
    // },
    isChangeMobile() {
      return this.lead.mobile;
    },
  },
  methods: {
    ...mapActions("SocialNetworkLeadsStore", [
      "A_GET_SUB_SOURCES",
      "A_GET_STATE_LEAD",
      "A_GET_OWNERS",
      "A_GET_PROGRAMS",
      "A_GET_SUB_SOURCE_SN",
      "A_GET_FAN_PAGE_PROGRAMS",
      "A_GET_FLYERS",
      "A_FORMAT_DATE",
      "A_GET_FLYER_ORGANIC_BY_PROGRAM",
      "A_GET_FLYER_PHONE_BY_PROGRAM",
    ]),
    getData(params) {
      this.dataComponentHijo.source_id = params.source_id;
      this.dataComponentHijo.contact_method_id = params.contact_method_id;
      this.dataComponentHijo.program_id = params.program_id;
      this.dataComponentHijo.from_flyer_contact_method =
        params.from_flyer_contact_method;
      this.lead.sub_source = params.contact_method_id;
      this.lead.fanpage_id = params.program_id;
      this.lead.source_id = params.source_id;
      if (params.source_name.length > 0) {
        this.dataComponentHijo.source_name = params.source_name[0].name;
      }
      if (params.contact_name.length > 0) {
        this.dataComponentHijo.contact_name = params.contact_name[0].name;
      }
      // let request = {
      //   state: this.lead.state_h,
      //   program_id: params.program_id,
      // };

      // if (params.contact_method_id != "") {
      //   this.A_GET_FLYERS(request);
      // }
    },

    async getOrganicFlyers() {
      const data = await this.A_GET_FLYER_ORGANIC_BY_PROGRAM();
      if (data.status === 200) {
        this.$emit("setOrganic");
      }
      const data2 = await this.A_GET_FLYER_PHONE_BY_PROGRAM();
      if (data2.status === 200) {
        this.$emit("setPhone");
      }
    },
    // Get Options Selects
    async statesAD() {
      const statesLead = (await this.A_GET_STATE_LEAD()).map((state) => ({
        label: state.state,
        value: state.slug,
      }));
      this.optionsStatesAd = [...this.optionsStatesAd, ...statesLead];
    },
    async ownersLeads() {
      const owners = (
        await this.A_GET_OWNERS({ modul: 15, body: { roles: "[]", type: "1" } })
      ).map((owner) => ({
        label: owner.user_name,
        value: owner.id,
      }));
      this.optionsOwners = [...this.optionsOwners, ...owners];
    },
    async programsAll() {
      const data = await this.A_GET_PROGRAMS();
      this.optionPrograms = [];
      this.optionPrograms = [...data];
      this.optionPrograms = this.optionPrograms.filter(
        (program) => program.id != 9
      );
    },
    async sub_sources() {
      await this.A_GET_SUB_SOURCE_SN();
    },

    selectSource(id) {
      if (id === 17) {
        this.lead.sourcesname_id = 17;
      } else if (id === 32) {
        this.lead.sourcesname_id = 32;
      }

      if (this.lead.source_id !== id) {
        this.lead.sub_source = null;
        this.lead.google_ads = null;
      }
      this.lead.source_id = 7;
    },

    openModalSelectFlyer() {
      this.openModal = !this.openModal;
    },
    selectFlyer(event, showModal = true) {
      this.lead.flyer = event.id;
      this.imageFlyer = event.urlImage;
      this.flyer_filename = event.filename;
      this.hasImageFlyerInSoft = event.hasImageFlyerInSoft ? true : false;
      this.metaUserId = event.metaUserId;
      if (showModal) {
        this.openModalSelectFlyer();
      }
    },
    async getMetaAdById() {
      if (!(this.lead.isFromChat && this.lead.ad_id)) return;
      try {
        this.showMetaAdInfo = true;
        const { data } = await MetaAdsService.getMetaAdById(this.lead.ad_id);
        if (!data?.ad_id) {
          this.showToast(
            "info",
            "top-right",
            "Oops!",
            "Xicon",
            "Ad not found in Meta Ads"
          );
          return;
        }
        const flyer = {
          id: data.ad_id,
          urlImage: data.thumbnail_url,
          filename: data.name,
          hasImageFlyerInSoft: data.thumbnail_url_in_soft,
          metaUserId: data.meta_user_id,
        };
        this.selectFlyer(flyer, false);
        setTimeout(() => {
          this.addNewContact();
        }, 1000);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.showMetaAdInfo = false;
      }
    },
    addNewContact() {
      if (this.flyer_filename) {
        if (this.lead.array_contacts.length > 0) {
          this.lead.array_contacts = this.lead.array_contacts.map(
            (element) => ({
              ...element,
              has_number: false,
            })
          );
        }

        this.lead.array_contacts.push({
          id: this.lead.flyer,
          flyer: this.flyer_filename,
          replies: this.lead.multiply,
          source: this.lead.source_id,
          source_name: this.dataComponentHijo.source_name,
          method: this.lead.sub_source,
          contact_name: this.dataComponentHijo.contact_name,
          has_number: this.lead.mobile ? true : false,
          has_image_flyer_in_meta: this.hasImageFlyerInSoft
            ? null
            : this.imageFlyer,
          url: this.imageFlyer,
          meta_user_id: this.metaUserId,
        });

        this.lead.flyer = null;
        this.flyer_filename = null;
        this.imageFlyer = null;
        this.lead.multiply = 1;
        this.hasImageFlyerInSoft = null;
      }
    },

    addNewContactOrganic() {
      this.lead.array_contacts = this.lead.array_contacts.map((element) => ({
        ...element,
        has_number: false,
      }));

      this.lead.array_contacts.push({
        id: null,
        flyer: "-",
        replies: 1,
        source: this.lead.source_id,
        source_name: this.dataComponentHijo.source_name,
        method: this.lead.sub_source,
        contact_name: this.dataComponentHijo.contact_name,
        has_number: this.lead.mobile ? true : false,
      });
    },

    deleteMethod(index) {
      this.lead.array_contacts.splice(index, 1);
      this.lead.array_contacts = this.lead.array_contacts.map((item, index) => {
        if (index == this.lead.array_contacts.length - 1) {
          return { ...item, has_number: this.lead.mobile ? true : false };
        } else {
          return item;
        }
      });
    },
    getAdPreview(ad) {
      this.showPreviewAd = true;
      this.adId = ad.id;
      this.adName = ad.flyer;
      this.metaUserId = ad.meta_user_id;
    },
  },
  watch: {
    lead: {
      handler: function (val, oldVal) {
        let arr = [
          {
            source_id: val.source_id,
            contact_method_id: val.sub_source,
            program_id: val.fanpage_id,
          },
        ];
        this.$emit("getSources", arr);
      },
      deep: true,
    },
    isChangeMobile() {
      this.lead.array_contacts = this.lead.array_contacts.map((item, index) => {
        if (index == this.lead.array_contacts.length - 1) {
          return { ...item, has_number: true };
        } else {
          return item;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.border-hover:hover {
  border: 2px solid #7000ff !important;
}

.btn-source {
  padding: 5px 14px !important;
  font-size: 13px;
}

.sub-sources {
  padding: 6px 10px;
  font-size: 14px;
  transition: 0.3s all ease-in-out;
}

.btn-fanpage {
  color: #fff;
  font-weight: normal;
}

.btn-program-1 {
  background: #dfa62e !important;
  color: #fff !important;
}

.btn-program-2 {
  background: #f9b402 !important;
  color: #fff !important;
}

.btn-program-3 {
  background: #00559b !important;
  color: #fff !important;
}

.btn-program-4 {
  background: #4c6534 !important;
  color: #fff !important;
}

.btn-program-5 {
  background: #00c5a2 !important;
  color: #fff !important;
}

.image-flyer {
  height: 168px;
  width: 168px;
  object-fit: cover;
  margin-left: -12px;
  margin-right: 20px;

  span {
    cursor: pointer;
    border: 3px solid transparent;
    transition: 0.3s all ease-in-out;
  }
}

.container-flyers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.select-flyer {
  span {
    cursor: pointer;
    border-color: #fc424a;
    border-width: 5px !important;
  }
}

.dark-layout {
  .select-flyer {
    span {
      cursor: pointer;
      border-color: #fc424a;
    }
  }
}

.t-white {
  color: #fff !important;
}

.t-dark {
  color: #1b2337;
}

.content-program {
  margin-top: 0.25rem;
}

.margin-flyer {
  margin-left: 9px;
}

.content-flyer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .text-left {
    margin-top: 0.5rem;
    margin-left: 1.1rem;
  }
}

.border-error-flyer {
  border: 2px solid #fc424a;
  overflow: hidden;
}

.container-flyers-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
